// Constantes
import { URL_BAKARTA } from '@/constants'
// Vuex
import { mapMutations } from 'vuex'

export default {
  name: 'ReferrerCatcher',
  created() {
    // Establecemos valor del referido
    this.setRefId(this.$route.params.id)
    // Redirigimos a Bakarta
    this.redirectToBakarta()
  },
  methods: {
    ...mapMutations('app', ['setReferred']),
    /**
     * Establecemos el valor del referido
     *
     * @param {string} id
     */
    setRefId(id) {
      this.setReferred({
        id,
        date: new Date()
      })
    },
    /**
     * Redirigimos a Bakarta (web)
     */
    redirectToBakarta() {
      window.location.href = URL_BAKARTA
    }
  }
}
